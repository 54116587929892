import { createApp } from 'vue'
import { Icon, Cell, CellGroup, Collapse, CollapseItem, Dialog } from 'vant';
import 'vant/lib/index.css';
import axios from 'axios';
import router from './router'
import App from './App.vue'
const app = createApp(App)

app.config.globalProperties.$axios = axios
app.config.globalProperties.$router = router
app.use(Collapse)
app.use(CollapseItem)
app.use(Cell)
app.use(CellGroup)
app.use(Icon)
app.use(Dialog)
app.use(router)
app.mount('#app')