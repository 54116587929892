import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
    {
        path: '/:catchAll(.*)',
        redirect: '/cn/index'
    },
    {
        path: '/cn/index',
        name: 'index',
        meta:{
            title:"index"
        },
        component: () =>
            import ( /* webpackChunkName: "Home" */ '../views/index.vue')
    },
    {
        path: '/cn/list',
        name: 'list',
        meta:{
            title:"list"
        },
        component: () =>
            import ( /* webpackChunkName: "Home" */ '../views/listInfo.vue')
    },
    {
        path: '/cn/detail',
        name: 'detail',
        meta:{
            title:"detail"
        },
        component: () =>
            import ( /* webpackChunkName: "Home" */ '../views/detail.vue')
    },
]
const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
    routes
})

export default router