<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import url('./assets/css/mui.skin.css');
html,body{
  padding: 0;
  margin: 0;
  height: 100%;
}
#app {
  font-family: system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  background-color: #ffffff;
}
</style>
